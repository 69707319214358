var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('vuexy-logo'),_c('h2',{staticClass:"brand-text text-primary ml-1"},[_vm._v(" "+_vm._s(_vm.$t('appName'))+" ")])],1),_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t('login.title'))+" ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t('login.description'))+" ")]),_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',{attrs:{"label-for":"login","label":_vm.$t('ui.loginChoice')}},[_c('validation-provider',{attrs:{"name":_vm.$t('ui.login'),"rules":_vm.getLoginValidationRules(_vm.login)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"login","name":"login-field","state":errors.length > 0 ? false : null,"placeholder":"john@example.com","autofocus":"","maxlength":_vm.emailOptions.maxlength,"required":""},model:{value:(_vm.login),callback:function ($$v) {_vm.login=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"login"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"password"}},[_vm._v(" "+_vm._s(_vm.$t('ui.password'))+" ")]),_c('b-link',{attrs:{"to":{
                                    name: 'forgot-password',
                                    params: {
                                        enteredLogin: _vm.login,
                                    },
                                }}},[_c('small',[_vm._v(_vm._s(_vm.$t('login.forgotPassword')))])])],1),_c('validation-provider',{attrs:{"name":_vm.$t('ui.password'),"rules":("required|max:" + (_vm.passwordOptions.maxlength))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"login-password","placeholder":_vm.$t('ui.password'),"maxlength":_vm.passwordOptions.maxlength},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"remember-me","name":"checkbox-1"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" "+_vm._s(_vm.$t('login.rememberMe'))+" ")])],1),_c('b-button',{attrs:{"variant":"primary","type":"submit","block":"","disabled":invalid},on:{"click":_vm.validationForm}},[_vm._v(" "+_vm._s(_vm.$t('login.signIn'))+" ")])],1)]}}])}),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('span',[_vm._v(_vm._s(_vm.$t('login.newUser') + ' '))]),_c('b-link',{attrs:{"to":{ name: 'register' }}},[_c('span',[_vm._v(_vm._s(_vm.$t('login.signUp')))])])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }