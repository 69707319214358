<template>
    <div class="auth-wrapper auth-v1 px-2">
        <div class="auth-inner py-2">
            <!-- Login v1 -->
            <b-card class="mb-0">
                <b-link class="brand-logo">
                    <vuexy-logo />

                    <h2 class="brand-text text-primary ml-1">
                        {{ $t('appName') }}
                    </h2>
                </b-link>

                <b-card-title class="mb-1">
                    {{ $t('login.title') }}
                </b-card-title>
                <b-card-text class="mb-2">
                    {{ $t('login.description') }}
                </b-card-text>

                <!-- form -->
                <validation-observer ref="loginForm" #default="{ invalid }">
                    <b-form class="auth-login-form mt-2" @submit.prevent>
                        <!-- login -->
                        <b-form-group
                            label-for="login"
                            :label="$t('ui.loginChoice')"
                        >
                            <validation-provider
                                #default="{ errors }"
                                :name="$t('ui.login')"
                                :rules="getLoginValidationRules(login)"
                            >
                                <b-form-input
                                    id="login"
                                    v-model.trim="login"
                                    name="login-field"
                                    :state="errors.length > 0 ? false : null"
                                    placeholder="john@example.com"
                                    autofocus
                                    :maxlength="emailOptions.maxlength"
                                    required
                                />
                                <small class="text-danger">{{
                                    errors[0]
                                }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- password -->
                        <b-form-group>
                            <div class="d-flex justify-content-between">
                                <label for="password">
                                    {{ $t('ui.password') }}
                                </label>
                                <b-link
                                    :to="{
                                        name: 'forgot-password',
                                        params: {
                                            enteredLogin: login,
                                        },
                                    }"
                                >
                                    <small>{{
                                        $t('login.forgotPassword')
                                    }}</small>
                                </b-link>
                            </div>
                            <validation-provider
                                #default="{ errors }"
                                :name="$t('ui.password')"
                                :rules="`required|max:${passwordOptions.maxlength}`"
                            >
                                <b-input-group
                                    class="input-group-merge"
                                    :class="
                                        errors.length > 0 ? 'is-invalid' : null
                                    "
                                >
                                    <b-form-input
                                        id="password"
                                        v-model="password"
                                        :type="passwordFieldType"
                                        class="form-control-merge"
                                        :state="
                                            errors.length > 0 ? false : null
                                        "
                                        name="login-password"
                                        :placeholder="$t('ui.password')"
                                        :maxlength="passwordOptions.maxlength"
                                    />

                                    <b-input-group-append is-text>
                                        <feather-icon
                                            class="cursor-pointer"
                                            :icon="passwordToggleIcon"
                                            @click="togglePasswordVisibility"
                                        />
                                    </b-input-group-append>
                                </b-input-group>
                                <small class="text-danger">{{
                                    errors[0]
                                }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- checkbox -->
                        <b-form-group>
                            <b-form-checkbox
                                id="remember-me"
                                v-model="status"
                                name="checkbox-1"
                            >
                                {{ $t('login.rememberMe') }}
                            </b-form-checkbox>
                        </b-form-group>

                        <!-- submit button -->
                        <b-button
                            variant="primary"
                            type="submit"
                            block
                            :disabled="invalid"
                            @click="validationForm"
                        >
                            {{ $t('login.signIn') }}
                        </b-button>
                    </b-form>
                </validation-observer>

                <b-card-text class="text-center mt-2">
                    <span>{{ $t('login.newUser') + ' ' }}</span>
                    <b-link :to="{ name: 'register' }">
                        <span>{{ $t('login.signUp') }}</span>
                    </b-link>
                </b-card-text>
            </b-card>
            <!-- /Login v1 -->
        </div>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
} from 'bootstrap-vue';
import VuexyLogo from '@core/layouts/components/Logo.vue';
import { required, email, max } from '@validations';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import useAuth from '@/views/pages/useAuth';
import { getAbilityByRoleName } from '@/libs/acl/getAbility';

export default {
    components: {
        // BSV
        BButton,
        BForm,
        BFormInput,
        BFormGroup,
        BCard,
        BCardTitle,
        BLink,
        VuexyLogo,
        BCardText,
        BInputGroup,
        BInputGroupAppend,
        BFormCheckbox,
        ValidationProvider,
        ValidationObserver,
    },

    mixins: [togglePasswordVisibility],

    props: {
        enteredLogin: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            login: this.enteredLogin,
            password: '',
            status: '',
            // validation rules
            required,
            email,
            max,
        };
    },

    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === 'password'
                ? 'EyeIcon'
                : 'EyeOffIcon';
        },

        locale() {
            return this.$i18n.locale;
        },
    },

    watch: {
        locale() {
            this.$refs.loginForm.reset();
        },
    },

    methods: {
        validationForm() {
            this.$refs.loginForm
                .validate()
                .then((success) => {
                    if (success) {
                        return Promise.resolve();
                    }

                    return Promise.reject(new Error('Validation error'));
                })
                .then(() => {
                    const { locale } = this.$i18n;
                    const authData = {
                        password: this.password,
                        login: this.login,
                        type: this.getAuthType(this.login),
                    };

                    return this.authUser({ locale, authData });
                })
                // .then(() => {
                //     // GET users/me
                // })
                .then(() => {
                    // TODO - Get user data
                    const userAbility = getAbilityByRoleName('admin');
                    const fakeUser = {
                        id: 0,
                        username: 'fakeuser',
                        ability: userAbility,
                    };

                    this.setUserData(fakeUser);
                    this.$ability.update(userAbility);

                    this.$router.push({
                        name: 'home',
                    });

                    // TODO - redirect to 2FA

                    // this.$router.push({
                    //     name: 'two-fa',
                    //     params: { enteredLogin: this.login },
                    // });
                })
                .catch((error) => {
                    console.log('error', error);
                });
        },
    },

    setup() {
        const {
            emailOptions,
            passwordOptions,
            authUser,
            getAuthType,
            getLoginValidationRules,
            setUserData,
        } = useAuth();

        return {
            // Reactive data
            emailOptions,
            passwordOptions,

            // Methods
            authUser,
            getAuthType,
            getLoginValidationRules,
            setUserData,
        };
    },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
