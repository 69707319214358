import axios from '@axios';
import { ref, reactive, toRefs, watch } from '@vue/composition-api';
import { setUserData } from '@/auth/utils';

// Vuexy jwt auth functionality
import useJwt from '@/@core/auth/jwt/useJwt';

import i18n from '@/libs/i18n/index';

import useHandleError from '@/utils/useHandleError';

export default function useAuth() {
    const { showErrorMessage } = useHandleError();

    const i18nReactive = reactive(i18n);

    const getJwtOptions = () => {
        return {
            loginEndpoint: `${i18nReactive.locale}/admin/auth`,
            registerEndpoint: `${i18nReactive.locale}/admin/register`,
            refreshEndpoint: `${i18nReactive.locale}/admin/refresh-token`,
            logoutEndpoint: `${i18nReactive.locale}/admin/logout`,
        };
    };

    const jwtOptions = ref(getJwtOptions());

    // Use JWT
    let { jwt } = useJwt(axios, jwtOptions.value);

    const inputs = reactive({
        usernameOptions: {
            specific: 'alpha',
            maxlength: 32,
            minlength: 4,
        },

        emailOptions: {
            specific: 'email',
            maxlength: 255,
            minlength: null,
        },

        phoneOptions: {
            specific: /^\+?[78]\d{0,10}$/i,
            maxlength: 12,
            minlength: 11,
        },

        passwordOptions: {
            maxlength: 64,
            minlength: 6,
        },

        twofaCodeOptions: {
            length: 6,
        },
    });

    const authUser = ({ authData }) => {
        return new Promise((resolve) => {
            jwt.login(authData)
                .then((response) => {
                    console.log('response', response);

                    const { token } = response.data;

                    jwt.setToken(token);

                    return resolve(response);
                })
                .catch(showErrorMessage);
        });
    };

    const getAuthType = (login = '') => {
        const phoneRegex = inputs.phoneOptions.specific;

        if (login.includes('@')) return 'email';
        if (phoneRegex.test(login)) return 'phone';

        return 'username';
    };

    const pushValidationRule = (loginRules, options) => {
        if (options.specific) loginRules.push(options.specific);

        if (options.maxlength) loginRules.push(`max:${options.maxlength}`);

        if (options.minlength) loginRules.push(`min:${options.minlength}`);

        return loginRules;
    };

    const getLoginValidationRules = (login = '') => {
        const loginType = getAuthType(login);

        let loginRules = ['required'];

        if (loginType === 'email') {
            pushValidationRule(loginRules, inputs.emailOptions);
        }

        if (loginType === 'username') {
            pushValidationRule(loginRules, inputs.usernameOptions);
        }

        if (loginType === 'phone') {
            loginRules = {
                regex: inputs.phoneOptions.specific,
                max: inputs.phoneOptions.maxlength,
                min: inputs.phoneOptions.minlength,
            };

            return loginRules;
        }

        return loginRules.join('|');
    };

    const setupJwtServiceWithNewLocale = () => {
        jwtOptions.value = getJwtOptions();

        const { jwt: jwtWithNewLocale } = useJwt(axios, jwtOptions.value);

        jwt = jwtWithNewLocale;
    };

    watch(
        () => i18nReactive.locale,
        () => setupJwtServiceWithNewLocale()
    );

    return {
        // Reactive data
        ...toRefs(inputs),

        // Methods
        authUser,
        getAuthType,
        getLoginValidationRules,
        setUserData,
    };
}
