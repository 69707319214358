import { initialAbility, roles } from './config';

export const getAbilityByRoleID = (roleID) => {
    const matchedRole = roles.find((role) => role.id === roleID);

    if (matchedRole) return matchedRole.ability;

    return initialAbility;
};

export const getAbilityByRoleName = (roleName) => {
    const matchedRole = roles.find((role) => role.name === roleName);

    if (matchedRole) return matchedRole.ability;

    return initialAbility;
};

export const _ = undefined;
